import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'; // Plugin per rilevare la lingua del browser
import It from './It.json';
import Es from './Es.json';
import En from './En.json';

// Configurazione delle traduzioni
console.log(i18n.language);

const resources = {
  es: {
    translation: Es
  },
  it: {
    translation: It
  },
  en: {
    translation: En
  }
};

i18n
  .use(LanguageDetector) // Rileva automaticamente la lingua del browser
  .use(initReactI18next) // Inizializza i18next
  .init({
    resources,
    fallbackLng: 'en',  
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
